/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';

const QUERY_GET_POST_STATIC = gql`
    query GetPostStaticContent {
        getPostStaticContent {
            enabled
        }
    }
`;

export type IGetPostStaticContent = {
    getPostStaticContent?: {
        enabled: boolean;
    };
};

export const useGetBlogFeatureFlag = () => {
    const { loading, error, data } = useQuery<IGetPostStaticContent>(QUERY_GET_POST_STATIC, {
        fetchPolicy: 'cache-and-network',
    });

    return { loading, error, data };
};
